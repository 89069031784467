.main-content
{
    position: relative;
    margin-left: 240px;
    transition: margin-left 500ms;
}

header
{
    position: fixed;
    top: 0;
    left: 240px;
    z-index: 100;
    width: calc(100% - 60px);
    background-color: #fff;
    height: 60px;
    display: flex;
    padding: 0rem 1rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    transition: left 500ms;
}

main
{
    background-color: #f1f5f9;
    margin-top: 60px;
    padding: 1rem 3rem;
}


@media only screen and (max-width: 1200px) 
{
    .main-content
    {
        margin-left: 60px;
    }
    
    .main-content header
    {
        left: 60px;
    }
}