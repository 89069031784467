.fundo
{
    font-family: "Poppins", sans-serif;
    background-color: #f1f5f9;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
}


.form
{
    background-color: white;
    max-width: 500px;
    width: 70%;
    padding: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
}


.form h1
{
    font-size: 30pt;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.form input[type=text], .form input[type=password]
{
    width: 100%;
    height: 45px;
    margin: 10px 0;
    border: 1px solid #999;
    background-color: #cecece;
    padding-left: 10px;
    border-radius: 5px;
}
.form input[type=text]:focus, .form input[type=password]:focus
{
    background-color: #fff;
    outline-color: #719ECE;
}

.form input[type=submit]
{
    width: 100%;
    height: 40px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: 0;
    border-radius: 5px;
    margin: 10px 0;
}
  
.form input[type=submit]:hover
{
    background-color: #095caf;
}

.linksenha
{
    color: #999;
    text-decoration: none !important;
    align-items: center;
}

.linksenha:hover
{
    color: #000;
}