/*SidBar*/

#sidebar-toogle
{
    display: none;
}

.sidebar-toogle-label
{
    cursor: pointer;
}

.sidebar-toogle-label:hover
{
    opacity: .5;
}

.sidebar
{
    height: 100%;
    width: 240px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: var(--main-color);
    color: #fff;
    overflow-y: auto;
    transition: width 500ms;
}

.sidebar-header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0rem 1rem;
}

.sidebar-menu
{
    padding: 1rem;
}

.sidebar li
{
    margin-bottom: 1.2rem;
}

.sidebar a
{
    color: #FFF;
    font-size: .9rem;
}

.sidebar a:hover, .sidebar .ativo
{
    opacity: .5;
}

.sidebar a span:last-child
{
    padding-left: .6rem;
}

#sidebar-toogle:checked ~ .sidebar
{
    width: 60px;
}

#sidebar-toogle:checked ~ .sidebar .sidebar-header h3 span, #sidebar-toogle:checked ~ .sidebar li span:last-child
{
    display: none;
}


#sidebar-toogle:checked ~ .sidebar .sidebar-header, #sidebar-toogle:checked ~ .sidebar li
{
    display: flex;
    justify-content: center;
}


#sidebar-toogle:checked ~ .main-content
{
    margin-left: 60px;
}

#sidebar-toogle:checked ~ .main-content header
{
    left: 60px;
}

@media only screen and (max-width: 1200px) 
{
    .sidebar
    {
        width: 60px;
        z-index: 150;
    }
    
    .sidebar .sidebar-header h3 span,  .sidebar li span:last-child
    {
        display: none;
    }
    
    
    .sidebar .sidebar-header, .sidebar li
    {
        display: flex;
        justify-content: center;
    }

    #sidebar-toogle:checked ~ .sidebar
    {
        width: 240px;
    }

    #sidebar-toogle:checked ~ .sidebar .sidebar-header h3 span, #sidebar-toogle:checked ~ .sidebar li span:last-child
    {
        display: inline;
    }


    #sidebar-toogle:checked ~ .sidebar .sidebar-header
    {
        display: flex;
        justify-content: space-between;
        
    }

    #sidebar-toogle:checked ~ .sidebar li
    {
        display: block;
        text-align: start;
    }

    #sidebar-toogle:checked ~ .main-content
    {
        margin-left: 60px;
    }

    #sidebar-toogle:checked ~ .main-content header
    {
        left: 60px;
    }
    
}