html,body {
    height: 100%;
    margin: 0;
    overflow: auto;
 }

.mymodal
{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: Arial, Helvetica, sans-serif;
    background: rgba(0,0,0,0.8);
    z-index: 300;
    opacity:1;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
}


.mymodal > div {
    width: 600px;
    position: relative;
    margin: 10% auto;
    padding: 15px 20px;
    background: #fff;
  }

.fechar {
    position: absolute;
    width: 30px;
    right: -15px;
    top: -20px;
    z-index: 3000;
    text-align: center;
    line-height: 30px;
    margin-top: 5px;
    background: #ff4545;
    border-radius: 50%;
    font-size: 16px;
    color: #8d0000;
    cursor: pointer;
    border: none;
}

.fechar:hover
{
    color: #ff4545;
    background-color: #8d0000;
}

