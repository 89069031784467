@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/*Global*/
:root
{
    --main-color: #027581;
    --color-dark: #1D2231;
    --text-grey: #8390A2;
}

*{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none !important;
    list-style-type: none;
    box-sizing: border-box;
}

h3
{
    font-size: 1.2rem;
}
